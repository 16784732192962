import { Box, FlexProps, VStack } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import { TopNav } from './TopNav';

type MainLayoutProps = {
  letFullWidth?: boolean;
  hideCountryPicker?: boolean;
} & FlexProps;
function MainLayout({
  letFullWidth,
  hideCountryPicker,
  ...rest
}: MainLayoutProps) {
  console.log('MainLayout');
  return (
    <VStack alignItems="stretch" gap="0" h="full" maxHeight="full">
      <TopNav
        letFullWidth={letFullWidth}
        hideCountryPicker={hideCountryPicker}
      />
      <Box
        as="main"
        style={{
          viewTransitionName: 'main-container',
          overflow: 'hidden',
          height: '100%',
          maxHeight: '100%',
        }}
        {...rest}
      >
        <Outlet />
      </Box>
    </VStack>
  );
}

export default MainLayout;
