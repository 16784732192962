import { createContext, useContext } from 'react';
import { User } from 'types';

export type AuthContextProps = {
  currentUser: User | null;
  expiryTime: number | null;
  setExpiryTime: (t: number | null) => void;
  setCurrentUser: (user: User | null) => void;
};
export const AuthContext = createContext<AuthContextProps>({
  currentUser: null,
  expiryTime: -1,
  setExpiryTime: () => {},
  setCurrentUser: () => {},
});

export const useAuth = () => useContext(AuthContext);
