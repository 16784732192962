import ky from 'ky';

export const api = ky.create({
  prefixUrl: process.env.REACT_APP_API_URL ?? 'https://api.rsvp.now/api/v1/',
  headers: {
    'Content-Type': 'application/json',
  },
  hooks: {
    beforeRequest: [
      request => {
        const token = localStorage.getItem('jwt');
        if (token) {
          request.headers.set('Authorization', `Bearer ${token}`);
        }
      },
    ],
  },
});
export const fileApi = ky.create({
  prefixUrl: process.env.REACT_APP_API_URL ?? 'https://api.rsvp.now/api/v1/',
  headers: {},
  hooks: {
    beforeRequest: [
      request => {
        const token = localStorage.getItem('jwt');
        if (token) {
          request.headers.set('Authorization', `Bearer ${token}`);
        }
      },
    ],
  },
});
