import { Image, Flex, HStack, useBreakpointValue } from '@chakra-ui/react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Goto } from 'components/GoTo';
import { useGoto } from 'hooks/useGoto';
import type { SupportedCountryFilter } from 'types';
import { MaxWidthBox } from '../components/MaxWidthBox';
import { ProfileMenu } from '../components/ProfileMenu';
import { CountryPicker } from './CountryPicker';

type TopNavProps = {
  letFullWidth?: boolean;
  hideCountryPicker?: boolean;
};
export const TopNav = ({ letFullWidth, hideCountryPicker }: TopNavProps) => {
  const logoSrc = useBreakpointValue({
    base: '/logo-short.svg',
    sm: '/logo.svg',
  });
  const location = useLocation();
  const { goto } = useGoto();

  const [searchParams] = useSearchParams();
  const country = searchParams.get('in') ?? 'Anywhere';

  const setCountryParam = (c: SupportedCountryFilter) => {
    searchParams.set('in', c);
    goto(`${location.pathname}?${searchParams.toString()}`, undefined, {
      replace: true,
    });
  };

  return (
    <Flex
      id="TopNav"
      height="5rem"
      borderBottom="1px solid"
      borderBottomColor="chakra-border-color"
      bg="chakra-body-bg"
      width="100%"
    >
      <MaxWidthBox
        width="full"
        maxW={letFullWidth ? 'full' : '100rem'}
        display="flex"
        justifyContent="space-between"
      >
        <Goto url={`/?in=${country}`} h="5rem">
          <Image
            src={logoSrc}
            height="2rem"
            margin="1.5rem"
            alt="RSVP.now logo"
          />
        </Goto>
        <HStack marginRight="1rem">
          {!hideCountryPicker && (
            <CountryPicker
              country={
                (searchParams.get('in') as SupportedCountryFilter) ?? 'global'
              }
              onChange={setCountryParam}
            />
          )}
          <ProfileMenu />
        </HStack>
      </MaxWidthBox>
    </Flex>
  );
};
